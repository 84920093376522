<template>
  <div class="wrap">

    <p class="header">
      <span>请选择问诊人信息</span>
      <!-- <span @click="onEdit" v-if="!is_edit">编辑</span> -->
      <!-- <span @click="onSuccess" v-else>完成</span> -->
      <span @click="toHome" v-if="isToHome" class="toHomeClass">我的咨询</span>
    </p>
    <div class="contain">
      <div class="list_wrap" v-for="(item, index) in list" :key="item.id" @click="showRadio ? onHandleClick(index) : onDetail(item)">
        <div class="list">
          <div class="left">
            <img src="../assets/img/boy.png" alt="">
            <div class="info">
              <p>{{item.interrogationName}} （{{getRelation(item.relation)}}）</p>
              <p>
                <span>{{item.sex == 1 ? '男' : '女'}}</span>
                <span>{{getBirthdayAge(item.birthday)}}岁</span>
              </p>
            </div>
          </div>
          <div class="radio" v-if="showRadio">
            <van-radio-group v-model="radio">
              <van-radio :name="index" checked-color="#42CEC8" v-if="!is_edit"></van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="bottom">
          <div class="icon del" @click.stop="onDel(item.id, index)">删除</div>
          <div class="icon edit" @click.stop="toEdit(item)">修改</div>
        </div>
      </div>
    </div>
    <div class="add_patient" @click="add_visits">
      <img class="icon" src="../assets/img/add_icon.png" />
      <span>添加咨询人信息</span>
    </div>
    <div class="btn" v-if="isShowBtn">
      <button @click="onNext">下一步</button>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant';
import { Toast } from 'vant';
import { getAge } from '@/utils/util';
import { getQueryVariable } from '../utils/util';
import BASE_URL from '@/request/base';
import axios from 'axios';
import { setTimeout } from 'timers';
export default {
  data() {
    return {
      radio: 0,
      is_edit: false,
      list: [],
      isShowBtn: true,
      isToHome: false,
      showRadio: true
    }
  },
  created() {
    // // console.log('created');
    // console.log('this.$route', this.$route.query)
    if (this.$route.query.showRadio) {
      this.showRadio = false;
    }

    if (this.$store.state.visitsType !== 4) {
      this.$store.dispatch('setPrice');
    }
    let userData = JSON.parse(localStorage.getItem('userData')) || '';
    // let userData = '';
    if (userData) {
      this.onList(userData.memberId);
    }
    if (this.$route.query.isShowBtn == 'false') {
      this.isShowBtn = false;
    }

  },
  mounted() {
    // // console.log('mounted');
    // const wxUrl = location.href;
    let userData = JSON.parse(localStorage.getItem('userData')) || '';
    localStorage.removeItem('freeRequestUUID');
    localStorage.removeItem('freeOrderId');
    localStorage.removeItem('ykfSecretToken');
    // let userData = '';
    let url = location.href;
    // console.log('url=====>', url)
    let app = getQueryVariable('appName') || '';
    // app = decodeURI(app);
    let phoneNum = getQueryVariable('phoneNumber') || '';
    let equitiesId = getQueryVariable('equitiesId') || '';
    let ykfSecretToken = getQueryVariable('ykfSecretToken') || '';
    localStorage.setItem('ykfSecretToken', ykfSecretToken);
    // localStorage.getItem('ykfSecretToken')
    app = decodeURI(app)
    // }
    if (phoneNum) {
      this.isToHome = true;
    }
    // var wxUrl = 'https://onlinedoctor-h5.yaojixin.com/choose_patients?phoneNumber=' + phoneNum + '&appName=' + app;
    var wxUrl = `${BASE_URL.wxUrl}?phoneNumber=${phoneNum}&appName=${app}&equitiesId=${equitiesId}&ykfSecretToken=${ykfSecretToken}`
    // console.log('wxUrl=========>', wxUrl);
    if ((!userData)) {
      var openId = localStorage.getItem('openid') || '';
      // let url = loc
      if (url.indexOf('code') == -1) {
        // if (url.indexOf('code') > -1) { // =================================================================================================
        let code = getQueryVariable('code') || '';
        let phone = getQueryVariable('phoneNumber') || '';
        let appName = getQueryVariable('state') || '';
        // let ykfSecretToken = getQueryVariable('ykfSecretToken') || '';
        // let equitiesId = getQueryVariable('equitiesId') || '';
        // // console.log('app============>', app);
        let params = null;
        if (ykfSecretToken && ykfSecretToken != 'undefined') {
          params = {
            phoneNum: phone,
            loginSourceName: app,
            loginSourceType: 1,
            ykfSecretToken: ykfSecretToken,
            equitiesId: equitiesId
          }
        } else {
          params = {
            phoneNum: phone,
            loginSourceName: app,
            loginSourceType: 1
          }
        }
        if (!phone) {
          this.$router.replace('/home');
          return;
        }

        // console.log('/server/member/login=======params=', params);
        this.$http('post', '/server/member/login', params, 1).then(res => {
          // // console.log(res);
          if (res.data.code == 200) {
            Toast(res.data.msg);
            this.$emit('onLoginSuccess')
            localStorage.setItem('userData', JSON.stringify(res.data.data));
            this.onThirdLogin(res.data.data.memberPassword);
            this.onList(res.data.data.memberId);
            // localStorage.setItem('freeRequestUUID', res.data.data.freeRequestUUID);
            if (ykfSecretToken && ykfSecretToken != 'undefined') {
              this.$http('post', '/server/order/addCreatingOrder', { memberId: res.data.data.memberId }, 1).then(res => {
                // console.log('创建临时免费问诊订单============', res);
                if (res.data.code == 200) {
                  localStorage.setItem('freeOrderId', res.data.data.orderId);
                }
              })
            }
            // this.$socket('', data => {
            //   // // console.log(data)
            // })
          } else {
            Toast(res.data.msg);
          }
        })
        // return false; // =================================================================================================
        this.$http('get', '/server/pay/getOfficialAccountOpenid?code=' + code, {}, 1).then(res => {
          // // console.log(res)
          if (res.data.code == 200) {
            // openid = res.data.data;
            localStorage.setItem('openid', res.data.data)
          }
        })
      } else {
        // return false; // =================================================================================================
        // // console.log('微信内置openid')
        const appid = 'wx41c11ad329b19f43'
        let http = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + wxUrl + '&response_type=code&scope=snsapi_base&state=' + app + '#wechat_redirect';
        // // console.log(http)
        location.href = http;
      }
    } else {
      if (ykfSecretToken && ykfSecretToken != 'undefined') {
        // console.log('=============================ykfSecretToken=============================');
        let phone = getQueryVariable('phoneNumber') || '';
        let appName = getQueryVariable('state') || '';
        let ykfSecretToken = getQueryVariable('ykfSecretToken') || '';
        let equitiesId = getQueryVariable('equitiesId') || '';
        let params = {
          phoneNum: phone,
          loginSourceName: app,
          loginSourceType: 1,
          ykfSecretToken: ykfSecretToken,
          equitiesId: equitiesId
        }
        this.$http('post', '/server/member/login', params, 1).then(res => {
          // // console.log(res);
          if (res.data.code == 200) {
            Toast(res.data.msg);
            this.$emit('onLoginSuccess')
            localStorage.setItem('userData', JSON.stringify(res.data.data));
            this.onThirdLogin(res.data.data.memberPassword);
            // this.onList(res.data.data.memberId);
            // localStorage.setItem('freeRequestUUID', res.data.data.freeRequestUUID);
            if (ykfSecretToken && ykfSecretToken != 'undefined') {
              this.$http('post', '/server/order/addCreatingOrder', { memberId: res.data.data.memberId }, 1).then(res => {
                // console.log('创建临时免费问诊订单============', res);
                if (res.data.code == 200) {
                  localStorage.setItem('freeOrderId', res.data.data.orderId);
                }
              })
            }
            // this.$socket('', data => {
            //   // // console.log(data)
            // })
          } else {
            Toast(res.data.msg);
          }
        })
      } else if (userData) {
        let loginInfo = JSON.parse(localStorage.getItem('userData')) || '';
        let params = {
          phoneNum: loginInfo.phoneNum,
          loginSourceName: loginInfo.loginSourceName,
          loginSourceType: 1
        }
        this.$http('post', '/server/member/login', params, 1).then(res => {
          if (res.data.code == 200) {
            // Toast(res.data.msg);
            this.$emit('onLoginSuccess');
            localStorage.setItem('userData', JSON.stringify(res.data.data));
            this.onThirdLogin(res.data.data.memberPassword);
            // this.$socket.sendSock('', data => {
            //   // // console.log(data)
            // })
          } else {
            Toast(res.data.msg);
          }
        })
      }
    }
  },
  methods: {
    toHome() {
      this.$router.replace({ path: '/consult' });
    },
    // 第三方登录
    onThirdLogin(password) {
      let loginInfo = JSON.parse(localStorage.getItem('userData')) || '';
      let params = {
        memberId: loginInfo.memberId
      }
      axios.get(BASE_URL.devUrl + '/server/sign/getSignInfo', { params: params }).then(res => {
        // axios.get('/dev_api/server/sign/getSignInfo', { params: params }).then(res => {  // 本地调试跨域打开
        // // console.log('签名', res);
        if (res.data.code == 200) {
          loginInfo.timestamp = res.data.data.timestamp;
          loginInfo.sign = res.data.data.sign;
          localStorage.setItem('userData', JSON.stringify(loginInfo))
          let query = {
            password: password
          }
          this.$http('post', '/cooperation/server/login', query).then(res => {
            // // console.log('登录', res);
          })
          // // console.log(loginInfo);
        } else {
          Toast(res.data.msg);
        }
      })
    },
    getRelation(relation) {
      return {
        1: '本人',
        2: '父母',
        3: '子女',
        4: '其他'
      }[relation]
    },
    getBirthdayAge(age) {
      return getAge(age)
    },
    onHandleClick(index) {
      this.radio = index;
    },
    // 列表
    onList(id) {
      let params = {
        memberId: id
      }
      this.$http('get', '/server/interrogationUserInfo/findAllByMemberId', params, 1).then(res => {
        if (res.data.code == 200) {
          this.list = this.list.concat(res.data.data);
        }
      })
    },
    // 详情
    onDetail(item) {
      this.$router.push({ path: '/detail', query: { item: JSON.stringify(item) } })
    },
    // 编辑
    onEdit() {
      this.is_edit = true;
    },
    // 完成
    onSuccess() {
      this.is_edit = false;
    },
    toEdit(item) {
      this.$router.push({ path: '/add_visits_info', query: { item: JSON.stringify(item) } })
    },
    // 添加
    add_visits() {
      this.$router.push({ path: '/add_visits_info' })
    },
    // 删除
    onDel(id, index) {
      Dialog.confirm({
        title: '提示',
        message: '确定要删除该问诊人信息吗！',
      })
        .then(() => {
          let params = {
            id: id
          }
          this.$http('get', '/server/interrogationUserInfo/deleteById', params, 1).then(res => {
            if (res.data.code == 200) {
              this.list.splice(index, 1)
              Toast('删除成功');
            }
          })
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    onNext() {
      if (this.list.length) {
        let relation = {
          age: this.getBirthdayAge(this.list[this.radio].birthday),
          sex: this.list[this.radio].sex == 1 ? '男' : '女'
        }
        // // console.log(this.list[this.radio].id)
        this.$store.state.relation = relation;
        this.$store.state.interrogationId = this.list[this.radio].id;
        this.$router.push('/fast_visits');
      } else {
        Toast('请添加一个问诊人信息');
      }
    },
  }
}
</script>
<style lang="less" scoped>
.wrap {
  min-height: calc(100% - 0.2rem);
  padding: 0 0.24rem;
  padding-top: 0.2rem;
  background: #f8f8f8;
  .contain {
    max-height: 9rem;
    overflow-y: auto;
    margin-bottom: 0.4rem;
  }
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
  }
  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.24rem;
    width: calc(100% - 0.48rem);
    border-radius: 2px;
    .radio {
      img {
        width: 0.4rem;
        height: 0.4rem;
      }
      .img {
        margin-right: 0.3rem;
      }
    }
    .left {
      display: flex;
      align-items: center;
      img {
        width: 0.72rem;
        height: 0.72rem;
        margin-right: 0.24rem;
      }
      .info {
        p:nth-child(1) {
          font-size: 0.28rem;
          color: #222;
          margin-bottom: 0.1rem;
        }
        p:nth-child(2) {
          font-size: 0.24rem;
          color: #999;
          span:nth-child(1) {
            margin-right: 0.1rem;
          }
        }
      }
    }
  }
  .list_wrap {
    // padding-bottom: .14rem;
    margin-bottom: 0.14rem;
    background: #fff;
    .bottom {
      border-top: 1px solid #e3e3e3;
      margin: 0 0.24rem;
      padding: 0.28rem 0;
      display: flex;
      justify-content: space-between;
      text-align: center;
      .icon {
        display: flex;
        align-items: center;
        font-size: 28rpx;
        color: #818997;
      }
      .del:before {
        content: "";
        display: inline-block;
        margin-right: 0.12rem;
        width: 0.3rem;
        height: 0.3rem;
        background: url("../assets/img/del.png") no-repeat center / 100% 100%;
      }
      .edit:before {
        content: "";
        display: inline-block;
        margin-right: 0.12rem;
        width: 0.3rem;
        height: 0.3rem;
        background: url("../assets/img/edit.png") no-repeat center / 100% 100%;
      }
    }
  }
  .add_patient {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    padding: 0.2rem;
    font-size: 0.3rem;
    color: #42cec8;
    // background: #8a8a8a;
    background: #fff;
    border-radius: 4px;
    .icon {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.2rem;
    }
    span {
      line-height: 0.3rem;
    }
  }
  .btn {
    width: 100%;
    height: 0.72rem;
    line-height: 0.72rem;
    margin-top: 0.4rem;
    button {
      background: #42cec8;
      width: 100%;
      color: #fff;
      font-size: 0.32rem;
      border: 0;
      border-radius: 4px;
    }
  }
}
.toHomeClass {
  text-decoration: underline;
  color: #42cec8;
  font-size: 0.25rem;
}
</style>
